/* eslint-disable react/prop-types */

import React, { Component } from "react";
import { Link } from "gatsby";

import Layout from "~components/Layout";
import { AppContext } from "~context/AppContext";

class PageNotFoundComponent extends Component {
  mounted = false;

  componentDidMount() {}

  //

  render() {
    return (
      <Layout className="_404 relative grid">
        <section className="grid-end--12 w-full h-screen relative flex flex-col justify-center">
          <h1 className="d1 font-bold text-bone">404</h1>
          <h1 className="d1 font-bold text-bone">Page not found.</h1>
          <Link className="mt-4" to="/">
            <span className="f3 font-medium text-bone mt-4">
              Feeling lucky? <span className="underline">Click me.</span>
            </span>
          </Link>
        </section>
      </Layout>
    );
  }
}

const PageNotFoundPage = () => {
  return (
    <AppContext.Consumer>
      {appContext => <PageNotFoundComponent appContext={appContext} />}
    </AppContext.Consumer>
  );
};

export default PageNotFoundPage;
